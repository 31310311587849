import { NgModule } from '@angular/core';
import { RouterModule, type Routes } from '@angular/router';

import { OfflineComponent } from "./pages/offline/offline.component";
import { NotFoundComponent } from "./pages/not-found/not-found.component";
import { ProjectGuard } from "./shared/guards/project.guard";
import { LostNetworkComponent } from "./shared/components/lost-network/lost-network.component";

const routes: Routes = [
    {
        path: 'project/:project_id',
        canActivate: [ProjectGuard],
        loadChildren: () => import('./shared/component.module').then(m => m.ComponentModule)
    },
    { path: 'offline', component: OfflineComponent },
    { path: '**', component: NotFoundComponent }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {}
